exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-curriculum-tsx": () => import("./../../../src/pages/curriculum.tsx" /* webpackChunkName: "component---src-pages-curriculum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-staff-tsx": () => import("./../../../src/pages/staff.tsx" /* webpackChunkName: "component---src-pages-staff-tsx" */),
  "component---src-pages-student-info-tsx": () => import("./../../../src/pages/student-info.tsx" /* webpackChunkName: "component---src-pages-student-info-tsx" */),
  "component---src-pages-timetable-tsx": () => import("./../../../src/pages/timetable.tsx" /* webpackChunkName: "component---src-pages-timetable-tsx" */)
}

